import React, { useState } from "react"
import PageWrapper from "../components/PageWrapper";

import Clinic from '../sections/rhino/rhinofind.js';  
import Rte from "../sections/landing/popular";
import AC from '../sections/landing/Acontentone';
import ACthree from '../sections/landing/acthree';
import ACfour from '../sections/landing/acfour';
import ACfive from '../sections/rhino/rhinofind.js';
import ACt from '../sections/nosefiller/fillcontent.js';
import CTA from '../sections/non/noncta.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Nongallery from "../sections/nosefiller/fillgallery.js";
import Lan from "../sections/nosefiller/fillhero.js";
import Testimonial from "../sections/rhino/revrhino.js";
import Liquid from "../sections/rhino/liquid.js";
import Anchoring from "../sections/rhino/anchor.js";
import Saveface from "../sections/landing/saveface";
import RhinoResults from "../sections/rhino/rhinoresults.js";
import Recovery from "../sections/rhino/rhinore.js";
import Abanner from '../sections/landing/Alanding';
import Blo from '../sections/landing/blo.js';
import Facts from '../sections/landing/lf.js';
import counter from '../sections/countersection/countersection.js'
import Sli from '../sections/rhino/rhinoslide';
import Pro from '../sections/landing/steps.js';
import Beauty from "../sections/non/nonbeauty.js";
import Acco from "../sections/nosefiller/fillfaq";
import Videos from "../sections/non/video.js";
import HeroDesktop from "../sections/non/nonhero";
import HeroImage from "../sections/non/nonmobile";
import HeroMobile from "../sections/non/noncontent";

import Acthree from '../sections/landing/acthree';
import SectionOne from '../sections/countersection/countersection.js'
import PopupForm from "../components/PopupForm/PopupForm"

const NoseFillerPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
  <>
 <Head title="Nose Filler, Rhinoplasty in London" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical nose filler using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
      <Lan />
      <Nongallery />
        <PopupForm
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
        />
      <ACt setIsModalOpen={setIsModalOpen}/>
  
      <Testimonial />
      
    
  
  <Acco setIsModalOpen={setIsModalOpen}/>
  
   <Rte />
   

   <ACfive />
 
   <Saveface />
 
     </PageWrapper>
    
  </>
)}
export default NoseFillerPage;
