import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { FaArrowRight } from "react-icons/fa";
import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
  Span,
} from "../../components/Core";
import { rgba } from "polished";
import { device } from "../../utils";
import Link from 'gatsby-link';
import imgL2HeroOval1 from "../../assets/image/jpeg/d1.jpg";
import imgL2HeroOval2 from "../../assets/image/png/l2-hero-oval2.png";
import imgL2HeroBg from "../../assets/image/jpeg/dermamina-clinic-treatments.jpg";
import imgL3HeroBg from "../../assets/image/jpeg/non-surgical-treatments.jpg";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import nonsurgical from "../../assets/image/jpeg/nonnose.jpg";
import trust from "../../assets/image/png/trust.svg"


const Items = styled.div`
@media ${device.lg} {
flex: 0 0 50%; margin-right: 20px;
}
`;

const Center = styled.div`
@media ${device.lg} {
display: flex;
flex-direction: row;
margin-right: 10px;
align-items: stretch;}

`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0;
  padding-left: 0;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #fff;
    font-size: 14px;
    font-weight: 300;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 10px;
      margin-right: 13px;
    }
  }
`;


const SectionStyled = styled(Section)`
background: url(${nonsurgical}) no-repeat;
background-size: cover;
background-position: center center;
  width: 100%;
  height:300px;

  @media ${device.lg} {
    background: url(${nonsurgical}) no-repeat;
    background-size: cover;
    background-position: center center;
      width: 100%;
    height:600px;
  }
`;


const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-bottom:80px;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;
  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }
  &.main {
    background: #154147;
    color: #efe8de;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

const ImageTop = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: -11%;
  left: -25%;
  border-radius: 50%;
  overflow: hidden;
  @media ${device.lg} {
    left: 10%;
  }
`;

const ImageBottom = styled(Box)`
  position: absolute;
  right: -13%;
  top: 54%;
  @media ${device.lg} {
    right: -23%;
  }
  @media ${device.xl} {
    right: -28%;
  }
`;

const NonsurgicalHero = ({setIsModalOpen}) => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <Section bg="rgba(0,24,47,.7)" className="pt-5" py={0}>
      <Container bg="rgba(0,24,47,.7)" fluid className="pl-0 pr-0">
        <Row className="align-items-center">
          <Col className="order-lg-2" lg="6">
      <SectionStyled  py={4}>
 
      </SectionStyled>
      </Col>
      <Col className="order-lg-1 " lg="6">
      <Section  py={4}>
        <Container  pl-0 pr-0>
          <Row><Col lg="12">
          <Title color="white" as="h1" ><b>NON SURGICAL <Span color="#00afc9">RHINOPLASTY</Span></b></Title>
              
                <Box mb={3}>
                    <ULStyled>
               <li>INSTANT RESULTS</li>
                      <li>NOSE JOB EXPERTS</li>
                      <li>NO RECOVERY NEEDED</li>
                    </ULStyled>
                  </Box>
                
                  <Center>
                    <Items>
                      <div onClick={() => setIsModalOpen(true)}>
                        <button type="button" className="fullbtn4">Book an Apppointment <FaArrowRight fontSize="18px"
                                                                                                      className="align-self-end float-right" />
                        </button>
                      </div>
                    </Items>
                    <Items>
                      <AnchorLink to="/non-surgical-nose-job/#gallery">
                      <button type="button" class="fullbtn5">Before & After Gallery</button>
                      </AnchorLink>
                    </Items>
                  </Center>

          </Col>
          </Row>
        </Container>
      </Section>
                       </Col>
                    
      </Row>
      </Container>
      </Section>
    </>
  );
};

export default NonsurgicalHero;